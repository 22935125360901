import React, { useContext, useRef } from "react";
import Navbar from "../components/Navbar";
import img2 from "../assets/ignite-flyer.jpg";
import FormContext from "../FormContext";
import { useParams } from "react-router-dom";
import { FaCalendarAlt } from "react-icons/fa";
import { BsFillClockFill, BsPinMap, BsArrowBarLeft } from "react-icons/bs";
import Footer from "../components/Footer";
import moment from "moment";
import { Link } from "react-router-dom";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { data } from "../data";
// const speakers = [
//   {
//     name: "Ziad Maalouf",
//     position: "Managing Director, Seven-up Bottling Company",
//     img: speaker3,
//   },
//   {
//     name: "Babatunde Irukera",
//     position: "Former CEO Federal Competition & Consumer Protection Commission",
//     img: speaker2,
//   },

//   {
//     name: "oluyomi banjo",
//     position: "national programme coordinator, environment and energy, unido",
//     img: speaker1,
//   },
//   {
//     name: "segun ogunleye",
//     position: "General Manager marketing, Seven-up Bottling Company",
//     img: speaker4,
//   },
// ];

const SingleSession = () => {
  const { sessions } = useContext(FormContext);

  const { id } = useParams();
  const sessionData = sessions.find(
    (session) => String(session?.sessionId) === String(id)
  );



  const handleBack = () => {
    window.history.back();
  };

  const sessionRef = useRef(null);
  const speakersRef = useRef(null);

  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.from(sessionRef.current, {
      x: -100,
      opacity: 0,
      duration: 1,
      ease: "in-out",
      scrollTrigger: {
        trigger: sessionRef.current,
        start: "top 80%",
        end: "top 50%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(speakersRef.current, {
      x: -100,
      opacity: 0,
      duration: 1,
      ease: "in-out",
      scrollTrigger: {
        trigger: speakersRef.current,
        start: "top 80%",
        end: "top 50%",
        toggleActions: "play none none reverse",
      },
    });
  }, []);
  return (
    <div className="w-full min-h-screen bg-gray-100">
      <Navbar />

      {/*  a button to go back to the sessions page */}
      <div className="w-11/12 mx-auto mt-8">
        <button
          className="text-primary-color font-nueu text-xl rounded-md flex items-center gap-2"
          onClick={handleBack}
        >
          <BsArrowBarLeft className="text-primary-color" />
          Back to Sessions
        </button>
      </div>

      <div className="w-full md:w-4/5 mx-auto mt-6 mb-6 p-3 md:p-0">
        <div className="w-full relative h-full md:h-80 flex flex-col md:flex-row gap-2 bg-white rounded-lg overflow-clip">
          <div className="w-full md:w-4/5 h-full">
            <img
              src={sessionData?.imageURL ? sessionData?.imageURL : img2}
              alt="session"
              className="w-full h-full rounded-lg md:rounded-l-lg object-top object-cover"
            />
          </div>
          <div className="w-full md:w-full justify-between flex flex-col gap-4 p-4">
            <h2 className="text-4xl font-semibold text-secondary-color font-nueu">
              {sessionData?.title}
            </h2>
            <p className="text-sm text-slate-700">{sessionData?.description}</p>
            <div className="flex flex-col gap-4">
              <div className="flex gap-2 items-center">
                <FaCalendarAlt className="text-secondary-color" />
                <p>{moment(sessionData?.date).format("MMMM Do YYYY")}</p>
              </div>
              <div className="flex gap-2 items-center">
                <BsFillClockFill className="text-secondary-color" />
                <p>
                  {" "}
                  {moment(sessionData?.date)
                    .startOf("day")
                    .add(8, "hours")
                    .format("h:mm a")}
                </p>
              </div>
              {
                // if isVirtual is true then display virtual else display physical
                sessionData?.isVirtual ? (
                  <div className="flex gap-2 items-center">
                    <BsPinMap className="text-secondary-color" />
                    <p>Virtual</p>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center">
                    <BsPinMap className="text-secondary-color" />
                    <p>{sessionData?.location}</p>
                  </div>
                )
              }
            </div>

            {/*  button to register for the session */}
            <Link
             to={`/register/${sessionData?.sessionId}`}
              className="bg-primary-color text-white py-2 mt-4 font-nueu text-xl rounded-md text-center hover:bg-opacity-80 transition-all duration-300 ease-in-out"
            >
              Register
            </Link>
          </div>

          <div className="absolute top-0 right-0 bg-red-500 flex w-[200px] ">
            <div className="font-medium  text-2xl flex flex-col   px-2 py-2 text-white">
            Price:
                    {sessionData?.sessionDefaultPromo ? (
                      <p className="flex items-center flex-row-reverse">
                        <del
                            className="text-gray-300 mx-2 font-light line-through text-sm"
                        >&#8358;{sessionData?.cost.toLocaleString()}</del>
                        &#8358;
                        {sessionData?.sessionDefaultPromo.amount.toLocaleString()}
                      </p> 
                    ) : (
                      <span className="mx-2">&#8358;{sessionData?.cost.toLocaleString()}</span>
                    )}
            </div>
          </div>
        </div>
       {/* details */}
      </div>


      <div
        className="w-11/12 mx-auto my-12 flex flex-col justify-center items-center"
        ref={speakersRef}
      >
        <h2 className="text-4xl font-semibold my-8 text-primary-color font-nueu text-center">
          Meet the Speakers
        </h2>
        <div className="grid grid-rows-1 grid-cols-1 overflow-x-auto md:grid-cols-4 items-center gap-4 md:gap-2 w-11/12 justify-center mx-auto">
          {data.map((speaker, index) => (
            <div
              key={index}
              className=" flex flex-col items-center gap-2 text-center"
            >
              <img
                src={speaker.img}
                alt={speaker.name}
                className=" w-[200px] h-[200px] md:w-[200px] md:h-[200px] rounded-full object-contain object-bottom bg-gradient-to-br from-primary-color to-secondary-color"
              />
              <h1 className="text-2xl md:text-lg font-semibold text-secondary-color font-nueu">
                {speaker.name}
              </h1>
              <p className="text-sm  text-slate-700 w-4/5 md:w-11/12">{speaker.position}</p>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default SingleSession;
