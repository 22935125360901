import React, { useContext } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import img1 from "../assets/img-1.jpg";
import img2 from "../assets/img-2.jpg";
import img3 from "../assets/img-3.jpg";
import img4 from "../assets/img13.jpg";
import { FaCalendarAlt } from "react-icons/fa";
import { BsFillClockFill, BsPinMap } from "react-icons/bs";
import { BiSolidWebcam } from "react-icons/bi";
import FormContext from "../FormContext";
import moment from "moment";
import { Link } from "react-router-dom";
import CountdownTimer from "../components/CountDownTimer";
import sessionImage from "../assets/seesionImg.jpg"
const Sessions = () => {
  const { sessions, formData, setFormData } = useContext(FormContext);
  const shuffleImages = [img1, img2, img3, img4];

  const handleSessionSelect = (session) => {
    // set the sessionId on localstorage 
    localStorage.setItem("sessionId", session.sessionId);     
            setFormData({
      ...formData,
      session: session.sessionId,
    });
  };


  return (
    <div className="w-full min-h-screen bg-white">
      <Navbar />

      {/* event banner here */}
      <div
        className="w-full h-screen flex flex-col gap-2 justify-center items-center  relative bg-cover bg-center bg-no-repeat bg-fixed bg-gradient-to-r from-primary-yellow to-primary-color"
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-black bg-opacity-50 w-full h-full absolute top-0 left-0"></div>
        <div className="flex flex-col gap-4 items-center justify-center z-10 text-white text-center px-4 py-8 md:py-16">
          <h1 className="text-4xl md:text-6xl  text-white font-bold text-center">
            From Seedling to <br />
            <span className="text-primary-yellow text-4xl md:text-8xl font-extrabold  ">
              Sequoia
            </span>
          </h1>
          <p className="text-white text-center text-xl">
            {" "}
            Register for the upcoming session
          </p>

          {/*  register for virtual or onsite with just the price tags */}

          <CountdownTimer sessionDate={sessions[0]?.date} />
        </div>
      </div>

      <div className="flex flex-col gap-4 w-11/12 mx-auto">
        <h2 className="text-2xl font-semibold mt-8 text-secondary-color font-nueu ">
          Available Sessions
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3  lg:grid-cols-4  w-full justify-center items-center gap-12 mb-4 grid-auto-rows-minmax(0, auto)">
          {sessions?.map((session, index) => (
            <div
              key={session.sessionId}
              className="card-container bg-white shadow-lg rounded-lg overflow-hidden relative w-full h-auto  transition-all duration-300 ease-in-out"
              style={{
                pointerEvents: session.availableSlots > 0 ? "auto" : "none",
                opacity: session.availableSlots > 0 ? 1 : 0.5,
              }}
            >
              {session?.soldOut && (
                <div className="absolute top-0 left-0 z-[999] bg-[#EF4B32] text-white px-4 py-2">
                  Sold Out
                </div>
              )}
              {session?.almostSoldOut && (
                <div className="absolute top-0 left-0 bg-primary-yellow text-white px-3 py-2">
                  Almost Sold Out
                </div>
              )}

              {/*  display price tags at the top right corner */}
              <div className="absolute top-0 right-0 bg-[#EF4B32] text-white px-3 py-2">
                {session.isVirtual ? (
                  <div className="flex items-center gap-2 text-sm font-bold">
                    <BiSolidWebcam className="" />
                    <p className="">Virtual</p>
                  </div>
                ) : (
                  <div className="flex items-center gap-2 text-sm font-bold">
                    <BsPinMap className="" />
                    <p className="">Physical</p>
                  </div>
                )}
              </div>

              <div className="">
                <img
                  src={
                    session.imageURL
                      ? session.imageURL
                      : shuffleImages[index % 4]
                  }
                  alt=""
                  className="w-full h-auto max-h-60 object-cover object-top"
                />
                <div className="px-2 py-6">
                  <div className="flex justify-between items-center">
                    <h1 className="text-2xl font-bold text-primary-color font-nueu">
                      {session?.title}
                    </h1>
                  </div>
                  <p className="text-secondary-color text-sm mt-2">
                    {session?.description}
                  </p>
                  <p className="mt-2 text-red-600 font-semibold">
                    Price:
                    {session?.sessionDefaultPromo ? (
                      <>
                        <del
                            className="text-gray-400 mx-2 font-light line-through text-sm"
                        >&#8358;{session?.cost.toLocaleString()}</del>
                        &#8358;
                        {session?.sessionDefaultPromo.amount.toLocaleString()}
                      </> 
                    ) : (
                      <span className="mx-2">&#8358;{session?.cost.toLocaleString()}</span>
                    )}
                  </p>

                  <div className="flex items-center mt-4">
                    <FaCalendarAlt className="text-primary-color" />
                    <p className="text-secondary-color ml-2">
                      {moment(session?.date).format("MMM Do YYYY")}
                    </p>
                  </div>
                  <div className="flex items-center mt-4">
                    <BsFillClockFill className="text-primary-color" />
                    <p className="text-secondary-color ml-2">
                      {moment(session?.date).format("HH:mm A")}
                    </p>
                  </div>
                </div>
                {
                  // display the overlay only if the session is not sold out
                  session.availableSlots > 0 ? (
                    <div className="overlay">
                      <Link
                        to={`/session/${session?.sessionId}`}
                        onClick={() => handleSessionSelect(session)}
                      >
                        <button className="bg-primary-color text-white px-4 py-2 rounded-lg">
                          Book Now
                        </button>
                      </Link>
                    </div>
                  ) : (
                    <div className="overlay">
                      <button
                        className="bg-red-500 text-white px-4 py-2 rounded-lg"
                        disabled
                      >
                        Sold Out
                      </button>
                    </div>
                  )
                }
              </div>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Sessions;
